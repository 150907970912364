export const GENDER = [
  { kor: '전체', val: 'ALL' },
  { kor: '남자', val: 'MEN' },
  { kor: '여자', val: 'WOMEN' },
]

export const KO_CLIMATE_TAG = ['맑은 날', '흐린 날', '비오는 날', '눈오는 날']
const EN_CLIMATE_TAG = ['Sunny', 'Cloudy', 'Rainy', 'Snowing']

export const KO_OCCUPATION_TAG = ['데일리', '학교/캠퍼스', '직장/출근']

export const KO_SPECIAL_TAG = ['소풍/나들이', '파티', '휴가']

const KO_OCCUPATION_SPECIAL_TAG = [...KO_OCCUPATION_TAG, ...KO_SPECIAL_TAG]

const EN_OCCUPATION_SPECIAL_TAG = [
  'Daily',
  'School',
  'Work',
  'Trip',
  'Party',
  'Holiday',
]

export const KO_STYLE_TAG = [
  '캐주얼',
  '빈티지',
  '스트릿',
  '모던',
  '스포티',
  '섹시',
  '클래식',
  '비치웨어/수영복',
  '발레/요가',
  '홈웨어',
  '골프/테니스',
]
const EN_STYLE_TAG = [
  'Casual',
  'Vintage',
  'Street',
  'Modern',
  'Sporty',
  'Sexy',
  'Classic',
  'Swimming',
  'BalletYoga',
  'Home',
  'GolfTennis',
]

const CLIMATE = {}

for (let i = 0; i < KO_CLIMATE_TAG.length; i++) {
  const koTag = KO_CLIMATE_TAG[i]
  const enTag = EN_CLIMATE_TAG[i]
  CLIMATE[koTag] = enTag
}

const OCCUPATION_SPECIAL = {}

for (let i = 0; i < KO_OCCUPATION_SPECIAL_TAG.length; i++) {
  const koTag = KO_OCCUPATION_SPECIAL_TAG[i]
  const enTag = EN_OCCUPATION_SPECIAL_TAG[i]
  OCCUPATION_SPECIAL[koTag] = enTag
}

const STYLE = {}

for (let i = 0; i < KO_STYLE_TAG.length; i++) {
  const koTag = KO_STYLE_TAG[i]
  const enTag = EN_STYLE_TAG[i]
  STYLE[koTag] = enTag
}

const ALL_TAG_MAP = {
  ...CLIMATE,
  ...OCCUPATION_SPECIAL,
  ...STYLE,
  ...GENDER.map((g) => g.kor),
}

export { ALL_TAG_MAP, CLIMATE, OCCUPATION_SPECIAL, STYLE }
