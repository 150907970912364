import { instance } from './instance'

export const getImgUrls = async (imgFiles) => {
  try {
    const formData = new FormData()
    formData.append('type', 'product')
    for (const file of imgFiles) {
      formData.append('files', file)
    }

    const ret = await instance.post(`/api/image/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      token: true,
    })

    return {
      success: true,
      data: ret.data.data.map((o) => o.url),
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const createProduct = async (body) => {
  try {
    await instance.post(`/api/product`, body, {
      token: true,
    })
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const putProduct = async (body, pid) => {
  try {
    await instance.put(
      `/api/product/admin`,
      { productId: pid, ...body },
      {
        token: true,
      }
    )
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getProducts = async (
  page,
  categoryId,
  keyword,
  sellStatus,
  approveStatus,
  sellerName,
  productSort,
  sortType,
  isDiscount,
  isApproveSeller,
  isProxy
) => {
  try {
    const query = {
      page,
      categoryId,
      keyword,
      sellStatus,
      approveStatus,
      sellerName,
      productSort,
      sortType,
      isDiscount,
      isApproveSeller,
      isProxy,
    }

    const queryStr = Object.keys(query)
      .filter((key) => query[key] || typeof query[key] === 'boolean')
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(
      `/api/product/seller/admin?${queryStr}`,
      {
        token: true,
      }
    )

    return data
  } catch (error) {
    throw error
  }
}

export const getProduct = async (pid) => {
  try {
    const { data } = await instance.get(`/api/product/detail/${pid}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}

export const searchProduct = async () => {
  try {
    const { data } = await instance.get(`/api/seller/seller-product`, {
      token: true,
    })

    return data.items
  } catch (error) {
    throw error
  }
}

export const removeProducts = async (productIds) => {
  try {
    let path = productIds.reduce((acc, id) => acc + `productIds=${id}&`, '')
    await instance.delete(`/api/product?${path}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const updateSellStatus = async (productIds, sellStatus) => {
  try {
    await instance.post(
      `/api/product/sell-status`,
      { productIds, sellStatus },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const updateApproveStatus = async (productIds, approveStatus) => {
  try {
    await instance.post(
      `/api/product/approve/admin`,
      { productIds, approveStatus },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const getHsCode = async (pid) => {
  try {
    const { data } = await instance.get(`/api/product/hs-code/${pid}`, {
      token: true,
    })

    return data
  } catch (error) {
    console.error(error)
    return
  }
}
