import { useLocation, useNavigate } from 'react-router-dom'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import styled from 'styled-components'
import { clearToken } from '../api/cookie'
import { clearLocalStorage } from '../api/storage'
import Image from '../element/Image'
import { Text } from '../element/Text'
import alarmIcon from '../static/alarm.png'
import blockIcon from '../static/block.png'
import couponIcon from '../static/coupon.png'
import dashboardIcon from '../static/dashboard.png'
import noticeIcon from '../static/notice.png'
import productIcon from '../static/product.png'
import promotionIcon from '../static/promotion.png'
import returnIcon from '../static/return.png'
import snapIcon from '../static/snap.png'
import transactionIcon from '../static/transaction.png'
import userIcon from '../static/user.png'

const NAV_MENU_SNAPS = [{ name: '스냅 게시물 목록', path: '/snap' }]
const NAV_MENU_PRODUCTS = [{ name: '상품 관리/목록', path: '/product' }]

const MENU_TRANSACTIONS = [
  { name: '판매 관리', path: '/transaction/sell' },
  { name: '정산 관리', path: '/transaction/list' },
]
const MENU_RETURNS = [
  { name: '반품 신청 내역', path: '/return/register' },
  { name: '반품 관리', path: '/return/list' },
]
const NAV_MENU_USERS = [
  { name: '앱 이용자 관리', path: '/user' },
  { name: '셀러 관리', path: '/user/seller' },
]

const NAV_MENU_NOTICES = [
  { name: '공지사항 & FAQ', path: '/notice' },
  { name: '파트너스 공지사항', path: '/notice-seller' },
]
const NAV_MENU_BLOCKS = [{ name: '신고 & 차단', path: '/block' }]

const NAV_MENU_REWARD = [
  { name: '쿠폰 발급 관리', path: '/reward/coupon-issued' },
  { name: '쿠폰 발행 내역', path: '/reward/coupon' },
  { name: '크레딧 관리', path: '/reward/credit' },
]

const NAV_MENU_ALARM = [{ name: '알림 관리/등록', path: '/alarm' }]

const NAV_MENU_PROMOTION = [
  { name: '기획전 관리', path: '/promo/admin' },
  { name: '기획전 등록', path: '/promo/register' },
  { name: '상품모음 관리', path: '/promo/product' },
]

export const MENU_LIST = [
  {
    name: '스냅 게시물 관리',
    key: 'snap',
    icon: snapIcon,
    list: NAV_MENU_SNAPS,
  },
  {
    name: '상품 관리',
    key: 'product',
    icon: productIcon,
    list: NAV_MENU_PRODUCTS,
  },
  {
    name: '판매/정산 관리',
    key: 'transaction',
    icon: transactionIcon,
    list: MENU_TRANSACTIONS,
  },
  {
    name: '반품 신청내역',
    key: 'return',
    icon: returnIcon,
    list: MENU_RETURNS,
  },
  {
    name: '유저정보',
    key: 'user',
    icon: userIcon,
    list: NAV_MENU_USERS,
  },
  {
    name: '신고/차단',
    key: 'block',
    icon: blockIcon,
    list: NAV_MENU_BLOCKS,
  },
  {
    name: '쿠폰&크레딧',
    key: 'reward',
    icon: couponIcon,
    list: NAV_MENU_REWARD,
  },
  {
    name: '알림 관리',
    key: 'alarm',
    icon: alarmIcon,
    list: NAV_MENU_ALARM,
  },
  {
    name: '기획전',
    key: 'promo',
    icon: promotionIcon,
    list: NAV_MENU_PROMOTION,
  },
  {
    name: '공지사항',
    key: 'notice',
    icon: noticeIcon,
    list: NAV_MENU_NOTICES,
  },
]

const NavigationTap = ({ seller }) => {
  const navigation = useNavigate()
  const location = useLocation()

  const clickNavTap = (path) => {
    window.scrollTo(0, 0)
    navigation(path)
  }

  const clickLogout = () => {
    navigation('/')
    clearLocalStorage()
    clearToken()
  }

  const isFocus = (path) => location.pathname === path

  return (
    <Box sx={{ border: '1px solid #e4e4e4', backgroundColor: 'white' }}>
      <Box paddingTop={10} paddingBottom={4}>
        <Box textAlign="center">
          <Text size="18px" bold padding="10px 0 0 0">
            {seller.name}
          </Text>
        </Box>

        <Box textAlign={'center'} onClick={clickLogout}>
          <Text padding="40px 0 40px 0" line cursor bold>
            로그아웃
          </Text>
        </Box>
      </Box>

      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <Image src={dashboardIcon} alt="dashboard" width={23} />
          <Text bold={isFocus('')} color="#707070" margin="0 0 0 10px">
            대시보드
          </Text>
        </Box>
      </AccordionDetails>

      {MENU_LIST.map(({ name, key, icon, list }, idx) => (
        <Box key={idx} sx={{ paddingY: 2 }}>
          <Accordion
            defaultExpanded={location.pathname.includes(key)}
            elevation={0}
            sx={{
              alignItems: 'center',
            }}
          >
            <AccordionSummary
              expandIcon={
                ({ order: -1 }, (<ExpandMoreIcon sx={{ color: '#707070' }} />))
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Image src={icon} alt="snap_icon" width={23} />
                <Text color="#707070" margin="0 0 0 10px">
                  {name}
                </Text>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {list.map(({ name, path }) => (
                <NavItem
                  key={name}
                  onClick={() => {
                    clickNavTap(path)
                  }}
                  focus={isFocus(path)}
                >
                  {name}
                </NavItem>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  )
}

export default NavigationTap

const NavItem = styled.p`
  padding: 30px 0 30px 30px;
  :hover {
    cursor: pointer;
  }
  font-size: 14px;
  font-weight: ${(props) => (props.focus ? 'bold' : 'normal')};
  color: ${(props) => (props.focus ? 'black' : '#707070')};
  background-color: ${(props) => (props.focus ? '#e4e4e4' : 'white')};
`
