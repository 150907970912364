export const getDate = (input, notTime, utc) => {
  if (!input) {
    return ''
  }

  const date = new Date(input)
  if (utc) {
    date.setHours(date.getHours() + utc)
  }
  const dateStr = date.toLocaleDateString()
  const hours = date.getHours().toLocaleString().padStart(2, '0')
  const minute = date.getMinutes().toLocaleString().padStart(2, '0')

  if (notTime) {
    return dateStr
  }

  return `${dateStr} ${hours}:${minute}`
}

export const isValidPwd = (pwd) => {
  const regex =
    /^(?=[A-Za-z\d@$!%*?&]{8,16}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/

  return regex.test(pwd)
}

export const isValidEmail = (email) => {
  // 이메일 유효성을 검사하는 정규 표현식
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  // 정규 표현식을 이용하여 유효성 검사
  return emailRegex.test(email)
}

export const getLayoutProfileImg = (url) => {
  if (!url) {
    return 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png'
  }
  return url
}

export const SellerGrade = {
  VIB: 'VIB',
  SPECIAL: 'SPECIAL',
  SPECIAL_1: 'SPECIAL-1',
  BASIC: 'BASIC',
  IB: 'IB',
  S: 'S',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
}

export const getFee = (grade) => {
  switch (grade) {
    case SellerGrade.VIB:
      return 0.16
    case SellerGrade.E:
      return 0.25
    case SellerGrade.IB:
    case SellerGrade.D:
      return 0.24
    case SellerGrade.C:
      return 0.23
    case SellerGrade.SPECIAL:
    case SellerGrade.B:
      return 0.22
    case SellerGrade.A:
      return 0.21
    case SellerGrade.SPECIAL_1:
      return 0.2
    case SellerGrade.S:
      return 0.18
    case SellerGrade.BASIC:
      return 0.26
    default:
      return 0.26
  }
}

export const priceWrapper = (price) => {
  price = price + ''
  price = price.replaceAll(',', '')

  let ret = ''
  if (price.length > 0) {
    for (let i = 1; i <= price.length; ++i) {
      ret = price[price.length - i] + ret

      if (i !== price.length && i % 3 === 0) {
        ret = ',' + ret
      }
    }
  }

  return ret
}

export const getNameFromValue = (list, value) => {
  const item = list.find((item) => item.value === value)
  if (!item) {
    return ''
  }
  return item.name
}

export const getValueFromName = (list, name) => {
  const item = list.find((item) => item.name === name)
  if (!item) {
    return ''
  }
  return item.value
}

export const SortType = {
  DESC: 'DESC',
  ASC: 'ASC',
}

/**
 * 복사하고 싶은 텍스트를 id로 찾아서 클립보드에 복사
 * @param {string} id
 */
export const copyTextById = (id) => {
  const text = document.getElementById(id).textContent
  const textarea = document.createElement('textarea')
  textarea.value = text

  // Select the text field
  textarea.select()
  textarea.setSelectionRange(0, 99999) // For mobile devices

  // Copy the text inside the text field
  navigator.clipboard.writeText(textarea.value)
}

export const copyTextDirect = (text) => {
  navigator.clipboard.writeText(text)
}

export const getResizeUrl = (url, size, origin) => {
  const type = url.split('/')[3]

  let URL = url.replace(
    type,
    origin ? `o-resized-${size}-${type}` : `resized-${size}-${type}`
  )

  // if (URL.startsWith("http://")) {
  //   URL.replace("http://", "https://");
  // }

  return URL
}
