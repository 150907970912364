import { Divider, Grid, SvgIcon } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'

import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import Navigation from '../../components/common/navigation'
import { PreviewProductModal } from '../../components/modal/modal'
import ProductTable from '../../components/product/product-table'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import { Text } from '../../element/Text'
import DefaultLayout from '../../global/DefaultLayout'
import useSelectModal from '../../hooks/common/modal/useSelectModal'
import useProductList from '../../hooks/product/useProductList'
import { MyFormControlLabel } from '../../components/common/MyFormControlLabel'
import SelectBrand from '../../components/common/SelectBrand'
import SelectCategory from '../../components/common/SelectCategory'

const SELL_STATUS = [
  { name: '전체', value: '' },
  { name: '판매대기', value: 'WAITING' },
  { name: '판매중', value: 'SELLING' },
  { name: '판매종료', value: 'ENDING' },
  { name: '품절', value: 'SOLDOUT' },
]

const APPROVE_STATUS = [
  { name: '전체', value: '' },
  { name: '승인요청', value: 'REQUEST' },
  { name: '승인대기', value: 'WAITING' },
  { name: '승인완료', value: 'APPROVED' },
  { name: '승인반려', value: 'REJECT' },
]

const PROXY_STATUS = [
  { name: '전체', value: '' },
  { name: '사입제품', value: true },
  { name: '비사입제품', value: false },
]

const ProductListPage = () => {
  const {
    approve,
    clickSearchBtn,
    products,
    checked,
    setChecked,
    clickHandler,
    page,
    setPage,
    count,
    preview,
    setPreview,
    id,
    option,
    setOption,
    sort,
    setSort,
  } = useProductList()

  const {
    modal: ApproveModal,
    openModal: openApproveModal,
    closeModal: closeApproveModal,
  } = useSelectModal({
    on: true,
    mainTxt: '승인하시겠습니까?',
    leftTxt: '승인',
    rightTxt: '취소',
    leftBg: 'red',
    rightBg: 'black',
    submit: () => {
      approve('APPROVED', checked)
      closeApproveModal()
    },
  })

  const {
    modal: RejectModal,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
  } = useSelectModal({
    mainTxt: '반려하시겠습니까?',
    leftTxt: '반려',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      approve('REJECT', checked)
      closeRejectModal()
    },
  })

  const clickApproveBtn = () => {
    if (checked.length === 0) {
      return
    }
    openApproveModal()
  }

  const clickRejectBtn = () => {
    if (checked.length === 0) {
      return
    }
    openRejectModal()
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      clickSearchBtn()
    }
  }

  return (
    <DefaultLayout isLoggedIn>
      <ApproveModal />
      <RejectModal />

      <Box sx={boxStyle}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          상품 검색
        </p>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <SelectCategory category={option.category} setOption={setOption} />

          <SelectBrand value={option.brand} setValue={setOption} />

          <>
            <Grid item xs={1.6}>
              <Text size="14px" color="#707070" bold>
                검색어
              </Text>
            </Grid>
            <Grid item xs={10}>
              <InputTextField
                width="450px"
                label=""
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOption({ ...option, keyword: e.target.value })
                }}
              />
            </Grid>
          </>

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <Text size="14px" color="#707070" bold>
                판매상태
              </Text>
            </Grid>
            <Grid item xs={10}>
              <RadioGroup row defaultValue="all">
                {SELL_STATUS.map(({ name, value }, idx) => (
                  <MyFormControlLabel
                    key={idx}
                    value="request"
                    control={
                      <Radio
                        onClick={() => {
                          setOption({
                            ...option,
                            sellStatus: value,
                          })
                        }}
                        size="small"
                        value={value}
                        checked={value === option.sellStatus}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </>

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <Text size="14px" color="#707070" bold>
                승인상태
              </Text>
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex' }}>
              <RadioGroup row defaultValue="all">
                {APPROVE_STATUS.map(({ name, value }, idx) => (
                  <MyFormControlLabel
                    key={idx}
                    value="request"
                    control={
                      <Radio
                        onClick={() => {
                          setOption({
                            ...option,
                            approvedStatus: value,
                          })
                        }}
                        size="small"
                        value={value}
                        checked={value === option.approvedStatus}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </>

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <Text size="14px" color="#707070" bold>
                사입업체
              </Text>
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex' }}>
              <RadioGroup row defaultValue="all">
                {PROXY_STATUS.map(({ name, value }, idx) => (
                  <MyFormControlLabel
                    key={idx}
                    value="request"
                    control={
                      <Radio
                        onClick={() => {
                          setOption({
                            ...option,
                            isProxy: value,
                          })
                        }}
                        size="small"
                        value={value}
                        checked={value === option.isProxy}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </>

          <Grid item xs={1.6}></Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              bg="black"
              bold
              borderRadius={2}
              height="40px"
              onClick={clickSearchBtn}
            >
              <SvgIcon component={SearchIcon} inheritViewBox />
              <p style={{ marginLeft: '10px' }}>상품 검색</p>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxStyle}>
        <Text size="18px" bold color="black">
          상품 목록
        </Text>

        <Divider sx={{ height: 16 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <Button
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius={2}
            height="44px"
            onClick={clickApproveBtn}
          >
            <p>선택 승인</p>
          </Button>

          <Box width="10px" />

          <Button
            bg="#fff0f0"
            color="#ff0000"
            bold
            borderRadius={2}
            height="44px"
            onClick={clickRejectBtn}
          >
            <p>선택 반려</p>
          </Button>
        </Box>
        <Divider sx={{ height: 16 }} />

        <ProductTable
          products={products}
          checked={checked}
          setChecked={setChecked}
          approve={approve}
          clickHandler={clickHandler}
          sort={sort}
          setSort={setSort}
          page={page}
          setPage={setPage}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
      {preview && (
        <PreviewProductModal
          id={id}
          open={preview}
          onClose={() => setPreview(false)}
          products={products}
          approve={approve}
        />
      )}
    </DefaultLayout>
  )
}

export default ProductListPage

export const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
