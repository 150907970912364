import { Divider, Snackbar, SvgIcon, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  cancelOrderItem,
  cancelReceivedComplete,
  getOrderItems,
  setReceivedComplete,
} from '../../api/order'
import {
  CancelInvoiceModal,
  MakeInvoiceModal,
} from '../../components/modal/invoice'

import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import RadioSelect from '../../components/RadioSelect'
import SelectModal from '../../components/SelectModal'
import DatePickerValue from '../../components/common/DataPicker'
import Navigation from '../../components/common/navigation'
import SellTable from '../../components/sell/sell-table'
import SetInvoiceModal from '../../components/sell/set-invoice-modal'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import { priceWrapper } from '../../utils'

const INNER_SHIPPING_STATUS = [
  { name: '전체', value: '' },
  { name: '발송요청', value: 'REQUEST_SENT' },
  { name: '발송완료', value: 'SENT_COMPLETE' },
  // { name: '배송중', value: 'SHIPPING' },
  // { name: '센터도착', value: 'ARRIVED_CENTER' },
  { name: '입고완료', value: 'RECEIVED_COMPLETE' },
  { name: '반송', value: 'RETURN' },
]

export const OUTER_SHIPPING_STATUS = [
  { name: '전체', value: '' },
  { name: '국내배송중', value: 'IN_TRANSIT' },
  { name: '출고대기중', value: 'PENDING_EXPORT' },
  { name: '출고완료', value: 'EXPORT_COMPLETE' },
  { name: '해외배송중', value: 'SHIPPING' },
  { name: '배송완료', value: 'DELIVERY_COMPLETE' },
]

const SETTLED_PAYMENT_STATUS = [
  { name: '전체', value: '' },
  { name: '정산대기', value: false },
  { name: '정산완료', value: true },
]

const RETURN_STATUS = [
  { name: '전체', value: '' },
  { name: '예(Y)', value: true },
  { name: '아니오(N)', value: false },
]

const SNACKBAR_MESSAGE = {
  NO_CHECK: '송장을 출력할 주문을 선택해주세요.',
  INVALID_CHECK: '구매자가 다른 주문이 있습니다.',
  INVALID_STATUS: '해외 배송 상태가 출고 대기가 아닙니다.',
}

const SellListPage = () => {
  const [checked, setChecked] = useState([])
  const [orderItems, setOrderItems] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [open, setOpen] = useState(false)
  const [isModify, setModify] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setChecked([])
  }

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getOrderItems({ page })
      if (success) {
        setOrderItems(data.data)
        setCount(data.count)
      }
    })()

    setChecked([])
  }, [page])

  const clickSearchBtn = async () => {
    if (!isValidDate(date.startAt, date.endAt)) {
      return
    }

    const params = {
      page,
      startAt: date.startAt.toISOString(),
      endAt: date.endAt.toISOString(),
      sellerName: keyword,
      innerShippingStatus: inner,
      outerShippingStatus: outer,
      isTransaction: settled,
      isReturn: ret,
    }

    if (keyword) {
      params['size'] = 1000
    }

    const { success, data } = await getOrderItems(params)
    if (success) {
      setOrderItems(data.data)
      setCount(data.count)

      if (keyword) {
        setSearched(true)
      } else {
        setSearched(false)
      }
    }
  }

  const [date, setDate] = useState({
    startAt: dayjs().startOf('day'),
    endAt: dayjs(),
  })
  const [keyword, setKeyword] = useState('')
  const [searchedSeller, setSearched] = useState(false)
  const [inner, setInner] = useState('')
  const [outer, setOuter] = useState('')
  const [settled, setSettled] = useState('')
  const [ret, setRet] = useState('')

  const [focusDate, setFocusDate] = useState('')

  const checkDateBtnList = [
    {
      name: '오늘',
      onClick: () => {
        setDate({ startAt: dayjs().startOf('day'), endAt: dayjs() })
        setFocusDate('오늘')
      },
    },
    {
      name: '1주일',
      onClick: () => {
        setDate({ startAt: dayjs().subtract(7, 'day'), endAt: dayjs() })
        setFocusDate('1주일')
      },
    },
    {
      name: '1개월',
      onClick: () => {
        setDate({ startAt: dayjs().subtract(1, 'month'), endAt: dayjs() })
        setFocusDate('1개월')
      },
    },
    {
      name: '3개월',
      onClick: () => {
        setDate({ startAt: dayjs().subtract(3, 'month'), endAt: dayjs() })
        setFocusDate('3개월')
      },
    },
  ]

  const [modal, setModal] = useState({
    on: false,
    mainTxt: '',
    leftTxt: '',
    rightTxt: '',
    leftBg: '',
    rightBg: '',
    clickLeft: () => {},
  })

  const closeStockedModal = () => {
    setModal({ ...modal, on: false })
    setChecked([])
  }

  const clickStockedModal = () => {
    if (checked.length === 0) {
      setSnackbar({ on: true, message: '선택된 주문이 없습니다.' })

      return
    }

    setModal({
      on: true,
      mainTxt: '입고처리 하시겠습니까?',
      leftTxt: '입고',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      clickLeft: async () => {
        if (
          checked.filter((o) => o.innerShippingStatus !== 'SENT_COMPLETE')
            .length !== 0
        ) {
          alert('발송완료 상태에서만 입고처리를 할 수 있습니다.')
          closeStockedModal()
          return
        }

        const { success } = await setReceivedComplete(checked.map((o) => o.id))

        if (!success) {
          return
        }

        for (const check of checked) {
          check.innerShippingStatus = 'RECEIVED_COMPLETE'
          check.outerShippingStatus = 'PENDING_EXPORT'
        }

        setOrderItems([...orderItems])
        setChecked([])
        closeStockedModal()
      },
    })
  }

  const clickCancelOrderItemModal = () => {
    if (checked.length === 0) {
      setSnackbar({ on: true, message: '선택된 주문이 없습니다.' })
      return
    }

    setModal({
      on: true,
      mainTxt: '취소처리 하시겠습니까?',
      leftTxt: '취소처리',
      rightTxt: '닫기',
      leftBg: 'black',
      rightBg: 'red',
      clickLeft: async () => {
        if (
          checked.filter((o) => o.innerShippingStatus !== 'REQUEST_SENT')
            .length !== 0
        ) {
          setSnackbar({
            on: true,
            message: '발송요청 상태에서만 취소처리를 할 수 있습니다.',
          })

          closeStockedModal()
          return
        }

        const orderId = checked[0].order.id
        const isSameOrder = checked.every((item) => item.order.id === orderId)
        if (!isSameOrder) {
          setSnackbar({
            on: true,
            message: '같은 주문번호 내에서만 취소할 수 있습니다.',
          })
          closeStockedModal()

          return
        }

        const userId = checked[0].user.id
        const checkedIds = checked.map((o) => o.id)
        const { success } = await cancelOrderItem(
          orderId,
          userId,
          checked.map((o) => o.id),
          checked.map((o) => o.orderCount)
        )

        if (!success) {
          setSnackbar({
            on: true,
            message: 'api 요청에 실패하였습니다.',
          })
          return
        }

        alert('주문취소가 완료되었습니다.')

        setOrderItems([
          ...orderItems.filter((item) => !checkedIds.includes(item.id)),
        ])
        setChecked([])
        closeStockedModal()
      },
    })
  }

  const clickCancelStockedModal = () => {
    if (checked.length === 0) {
      setSnackbar({ on: true, message: '선택된 주문이 없습니다.' })
      return
    }

    setModal({
      on: true,
      mainTxt: '입고취소 하시겠습니까?',
      leftTxt: '입고취소',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      clickLeft: async () => {
        if (
          checked.filter((o) => o.innerShippingStatus !== 'RECEIVED_COMPLETE')
            .length !== 0 ||
          checked.filter((o) => o.outerShippingStatus !== 'PENDING_EXPORT')
            .length !== 0
        ) {
          alert(
            '입고완료 상태와 출고 대기 상태에서만 입고취소 처리를 할 수 있습니다.'
          )
          closeStockedModal()
          return
        }

        const { success } = await cancelReceivedComplete(
          checked.map((o) => o.id)
        )

        if (!success) {
          return
        }

        for (const check of checked) {
          check.innerShippingStatus = 'SENT_COMPLETE'
          check.outerShippingStatus = 'IN_TRANSIT'
        }

        setOrderItems([...orderItems])
        setChecked([])
        closeStockedModal()
      },
    })
  }

  const [makeInvoiceModal, setMakeInvoiceModal] = useState(false)
  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false)
  const [snackbar, setSnackbar] = useState({
    on: false,
    message: '',
  })

  const openMakeInvoiceModal = () => {
    // check purchase people
    if (!checked.length) {
      setSnackbar({ on: true, message: SNACKBAR_MESSAGE.NO_CHECK })
      return
    }
    const first = checked[0].user.id
    const diffrenUser = checked.filter((c) => c.user.id !== first)
    if (diffrenUser.length) {
      setSnackbar({ on: true, message: SNACKBAR_MESSAGE.INVALID_CHECK })
      return
    }
    const checkStatus = checked.filter(
      (c) => c.outerShippingStatus !== OUTER_SHIPPING_STATUS[2].value
    )
    if (checkStatus.length) {
      setSnackbar({ on: true, message: SNACKBAR_MESSAGE.INVALID_STATUS })
      return
    }

    setMakeInvoiceModal(true)
  }
  const closeMakeInvoiceModal = () => setMakeInvoiceModal(false)

  const cancelId = useRef('')

  return (
    <DefaultLayout isLoggedIn>
      <>
        <CancelInvoiceModal
          open={cancelInvoiceModal}
          onClose={() => setCancelInvoiceModal(false)}
          cancelId={cancelId}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
        />
        <CancelInvoiceModal
          open={cancelInvoiceModal}
          onClose={() => setCancelInvoiceModal(false)}
          cancelId={cancelId}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
        />
        <MakeInvoiceModal
          open={makeInvoiceModal}
          onClose={closeMakeInvoiceModal}
          checked={checked}
          setChecked={setChecked}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          setSnackbar={setSnackbar}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.on}
          onClose={() => setSnackbar(false)}
          message={snackbar.message}
          autoHideDuration={3000}
        />
        <SelectModal
          open={modal.on}
          handleClose={closeStockedModal}
          clickModalBtn={modal.clickLeft}
          mainTxt={modal.mainTxt}
          leftTxt={modal.leftTxt}
          rightTxt={modal.rightTxt}
          leftBg={modal.leftBg}
          rightBg={modal.rightBg}
        />

        <SetInvoiceModal
          isModify={isModify}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          open={open}
          handleClose={handleClose}
          checked={checked}
        />
      </>

      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            날짜/상품별 조회
          </p>

          <Box width={30} />

          <p
            style={{
              marginTop: 'auto',
              color: '#707070',
            }}
          >
            * 조회 기간 선택 시 결제 기준으로 적용됩니다.
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox ai="start">
            <p
              style={{
                color: '#707070',
              }}
            >
              조회기간 <span style={{ color: 'red' }}>*</span> :
            </p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {checkDateBtnList.map(({ name, onClick }, idx) =>
                focusDate === name ? (
                  <Button
                    key={idx}
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                ) : (
                  <Button
                    key={idx}
                    color="#707070"
                    bg="white"
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
            <DatePickerValue date={date} setDate={setDate} />
          </Box>
        </OptionContainer>

        <p
          style={{
            marginLeft: 130,
            color: '#ff0000',
          }}
        >
          조회기간은 최대 1년 이내 기간으로 입력해주세요.
        </p>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              판매자 검색 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label="판매자 닉네임을 정확히 입력해주세요."
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
          />
        </OptionContainer>

        <RadioSelect
          label="국내배송상태"
          list={INNER_SHIPPING_STATUS}
          setState={setInner}
          state={inner}
        />

        <RadioSelect
          label="해외배송상태"
          list={OUTER_SHIPPING_STATUS}
          setState={setOuter}
          state={outer}
        />

        <RadioSelect
          label="정산여부"
          list={SETTLED_PAYMENT_STATUS}
          setState={setSettled}
          state={settled}
        />

        <RadioSelect
          label="반품여부"
          list={RETURN_STATUS}
          setState={setRet}
          state={ret}
        />

        <OptionContainer>
          <Button
            size="large"
            bold
            borderRadius={2}
            height="40px"
            sx={{
              marginLeft: '130px',
            }}
            onClick={clickSearchBtn}
          >
            <SvgIcon component={SearchIcon} inheritViewBox />
            <p style={{ marginLeft: '10px' }}>상품 검색</p>
          </Button>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography sx={{ fontWeight: 700 }}>판매 목록</Typography>

        <Box display="flex" gap={2} marginTop={3}>
          <Button
            height="40px"
            bg="#e4e4e4"
            color="black"
            bold
            borderRadius="10px"
            onClick={clickStockedModal}
          >
            <p>선택 입고완료 처리</p>
          </Button>

          <Button
            height="40px"
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius="10px"
            onClick={openMakeInvoiceModal}
          >
            <p>선택 송장 출력(DHL)</p>
          </Button>

          <Button
            height="40px"
            color="#f57979"
            bg="#fff0f0"
            bold
            borderRadius="10px"
            onClick={clickCancelOrderItemModal}
          >
            <p>선택 주문 취소</p>
          </Button>
        </Box>

        {searchedSeller && (
          <>
            <Divider sx={{ height: 30 }} />

            <Box marginTop={3} display="flex" gap={30}>
              <Typography sx={{ fontWeight: 700 }}>
                총 판매매출액 : $ {getTotalSales(orderItems)}
              </Typography>
              <Typography sx={{ fontWeight: 700 }}>
                총 정산금액 : ₩ {getTotalTransactionPrice(orderItems)}
              </Typography>
              <Typography sx={{ fontWeight: 700 }}>
                판매건수 : {orderItems.length}
              </Typography>
              <Typography sx={{ fontWeight: 700 }}>
                구매자 수 : {getBuyerCnt(orderItems)}
              </Typography>
            </Box>
          </>
        )}

        <Divider sx={{ height: 30 }} />

        <SellTable
          orderItems={orderItems}
          checked={checked}
          setChecked={setChecked}
          handleOpen={handleOpen}
          setModify={setModify}
          setModal={setModal}
          closeStockedModal={closeStockedModal}
          setOrderItems={setOrderItems}
          clickCancelStockedModal={clickCancelStockedModal}
          cancelId={cancelId}
          openCancelInvoiceModal={() => setCancelInvoiceModal(true)}
        />

        {!searchedSeller && (
          <Navigation page={page} setPage={setPage} count={count} />
        )}
      </Box>
    </DefaultLayout>
  )
}
export default SellListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children, ai, width, left }) => (
  <Box
    width={width ?? 100}
    display="flex"
    justifyContent={left ? 'left' : 'right'}
    alignItems={ai ? ai : 'center'}
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

const getBuyerCnt = (orderItems) => {
  const set = new Set()
  for (const item of orderItems) {
    set.add(item.user.id)
  }
  return set.size
}

const getTotalTransactionPrice = (orderItems) => {
  return priceWrapper(
    orderItems.reduce((pre, cur) => pre + cur.transactionPrice, 0)
  )
}

const getTotalSales = (orderItems) => {
  return orderItems.reduce((pre, cur) => pre + cur.orderPrice, 0).toFixed(2)
}

const isSameItem = (checked) => {
  const first = checked[0]
  return (
    checked.every((o) => o.outerInvoiceNumber === first.outerInvoiceNumber) &&
    checked.every((o) => o.outerShippingCompany === first.outerShippingCompany)
  )
}

const existEmptyItem = (checked) => {
  return checked.some((o) => !o.outerInvoiceNumber)
}

export const isValidDate = (startAt, endAt) => {
  if (startAt && endAt) {
    const start = new Date(startAt)
    const end = new Date(endAt)

    if (start > end) {
      alert('조회 기간 끝나는 날짜가 더 이를 수 없습니다.')
      return false
    }

    if (end - start > 1000 * 60 * 60 * 24 * 365) {
      alert('조회기간은 최대 1년 이내로 선택해주세요.')
      return false
    }

    return true
  }
}
