import { useContext, useEffect, useState } from 'react'
import { getProducts, updateApproveStatus } from '../../api/product'
import useCustomNavigation from '../../components/common/useCsutomNavigation'
import { ProductSearchContext } from '../../provider/productSearch'

const useProductList = () => {
  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  const [checked, setChecked] = useState([])
  const [products, setProducts] = useState([])

  const [count, setCount] = useState(0)
  const { page, setPage } = useCustomNavigation()
  const [sort, setSort] = useState({ productSort: '', sortType: '' })

  const { option, setOption } = useContext(ProductSearchContext)

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  const clickSearchBtn = async (page) => {
    page = typeof page === 'number' ? page : 1
    const { data, count } = await getProducts(
      page,
      option.category.id,
      option.keyword,
      option.sellStatus,
      option.approvedStatus,
      option.brand,
      sort.productSort,
      sort.sortType,
      null,
      null,
      option.isProxy
    )
    setProducts(data)
    setCount(count)
    setPage(page)
  }

  const approve = async (approveStatus, checked) => {
    if (checked.length === 0) {
      return
    }
    const { success } = await updateApproveStatus(
      checked.map((o) => o.id),
      approveStatus
    )

    if (!success) {
      return
    }

    for (const product of checked) {
      product.approveStatus = approveStatus
    }

    setProducts([...products])
    setChecked([])
  }

  useEffect(() => {
    clickSearchBtn(page)
    setChecked([])
  }, [page, sort])

  return {
    approve,
    clickSearchBtn,
    products,
    checked,
    setChecked,
    clickHandler,
    page,
    setPage,
    count,
    preview,
    setPreview,
    id,
    option,
    setOption,
    sort,
    setSort,
  }
}

export default useProductList
