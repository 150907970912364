import { instance } from './instance'

export const getOrderItems = async ({
  page,
  startAt,
  endAt,
  sellerName,
  innerShippingStatus,
  outerShippingStatus,
  isTransaction,
  isReturn,
  size,
}) => {
  try {
    const queryObj = {
      startAt,
      endAt,
      sellerName,
      innerShippingStatus,
      outerShippingStatus,
      isTransaction,
      isReturn,
      size,
    }

    let query = `page=${page}`
    for (const [key, value] of Object.entries(queryObj)) {
      if (value || typeof value === 'boolean') {
        query += `&${key}=${value}`
      }
    }

    const { data } = await instance.get(`/api/order/admin?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const setOrderInvoice = async (body) => {
  try {
    const { data } = await instance.post(`/api/order/invoice/admin`, body, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const setReceivedComplete = async (orderItemIds) => {
  try {
    const { data } = await instance.post(
      `/api/order/set-received-complete`,
      {
        orderItemIds,
      },
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const cancelReceivedComplete = async (orderItemIds) => {
  try {
    const { data } = await instance.post(
      `/api/order/cancel-received-complete`,
      {
        orderItemIds,
      },
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getSettlementItem = async (id, page) => {
  try {
    const { data } = await instance.get(`/api/order/settlement`, {
      params: { sellerId: id },
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const cancelOrderItem = async (
  orderId,
  userId,
  orderItemIds,
  cancelCounts
) => {
  try {
    const { data } = await instance.post(
      `/api/order/cancel/admin`,
      {
        orderId,
        orderItemIds,
        userId,
        cancelCounts,
      },
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
