import { getDate } from '../../utils'

import { Box, Snackbar } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import SelectModal from '../../components/SelectModal'
import { Button } from '../../element/Button'
import CustomSwitch from '../common/Switch'
import { removeProductSet } from '../../api/productSet'

const heads = ['사용', '순서', '제목', '등록일시', '', '']

const ProductSetListTable = ({ items, setItems, storeItems, loadUpdate }) => {
  const changeOrderNumber = (e, item) => {
    if (isEnd(item.endAt)) {
      return
    }

    const inputVal = e.target.value
    if (!/^\d{0,2}$/.test(inputVal)) {
      return
    }
    const number = Number(inputVal)
    item.orderNumber = number === 0 ? null : Number(inputVal)
    setItems([...items])
  }

  const clickActive = (e, item) => {
    item.status = item.status === 'ACTIVE' ? 'UNUSED' : 'ACTIVE'
    setItems([...items])
  }

  const [modal, setModal] = useState({
    on: false,
    onClick: () => {},
  })

  const remove = async (item) => {
    const { success } = await removeProductSet([item.id])
    if (!success) {
      alert('삭제에 실패하였습니다.')
      return
    }

    const filtered = items.filter((i) => i.id !== item.id)
    setItems(filtered)
    setModal({ on: false, onClick: () => {} })
  }

  const [snackbar, setSnackBar] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="기획전 id가 복사되었습니다."
      />
      <SelectModal
        open={modal.on}
        handleClose={() => {
          setModal((prev) => ({ ...prev, on: false }))
        }}
        clickModalBtn={modal.onClick}
        mainTxt="정말 삭제하시겠습니까?"
        leftTxt="삭제"
        rightTxt="취소"
        leftBg="black"
        rightBg="red"
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box></Box>
        <Button width={80} height={40} borderRadius="6px" onClick={storeItems}>
          저장
        </Button>
      </Box>
      <Table>
        <thead>
          <tr>
            {heads.map((head, idx) => (
              <Th key={idx}>{head}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <tr
                key={item.id}
                style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              >
                <Td width={100}>
                  {isEnd(item.endAt) ? (
                    '종료'
                  ) : (
                    <CustomSwitch
                      color="success"
                      checked={item.status === 'ACTIVE'}
                      onClick={(e) => {
                        clickActive(e, item)
                      }}
                    />
                  )}
                </Td>
                <Td width={100}>
                  <StyledInput
                    value={
                      isEnd(item.endAt)
                        ? ''
                        : item.orderNumber
                        ? item.orderNumber
                        : ''
                    }
                    onChange={(e) => changeOrderNumber(e, item)}
                  />
                </Td>

                <Td width={900}>{item.title}</Td>

                <Td width={150}>{getDate(item.createdAt)}</Td>
                <Td>
                  <Button
                    color="blue"
                    bg="#BACAFF"
                    borderRadius="10px"
                    onClick={() => {
                      loadUpdate(item.id)
                    }}
                  >
                    수정
                  </Button>
                </Td>
                <Td>
                  <Button
                    color="red"
                    bg="#FFF0F0"
                    borderRadius="10px"
                    onClick={() => {
                      setModal({
                        on: true,
                        onClick: () => {
                          remove(item)
                        },
                      })
                    }}
                  >
                    삭제
                  </Button>
                </Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default ProductSetListTable

const Table = styled('table')`
  width: 100%;
  border-top: 2px solid black;
`

const Th = styled('th')`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  vertical-align: middle;
  text-align: center;
`

const Td = styled('td')`
  width: ${(props) => props.width}px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  border: none;
  border-bottom: 4px solid;
  outline: none;
  padding-left: 14px;

  font-size: 16px; /* 폰트 크기 */
  font-weight: bold; /* 폰트 두께 */
  font-family: Arial, sans-serif; /* 폰트 패밀리 */
  text-align: left; /* 텍스트 정렬 */
`

const isEnd = (endAt) => {
  const now = Date.now()
  const elevenHoursInMillis = 13 * 60 * 60 * 1000

  const dateMinusElevenHours = new Date(now - elevenHoursInMillis)
  return new Date(endAt).getTime() < dateMinusElevenHours.getTime()
}
