import { instance } from './instance'

export const findProductSet = async (id) => {
  try {
    const { data } = await instance.get(`/api/promotion/p-set/${id}`, {
      token: true,
    })
    return { success: true, data: data.data }
  } catch (error) {
    return { success: false }
  }
}

export const createProductSet = async (body) => {
  try {
    delete body.products
    const data = await instance.post(`/api/promotion/p-set`, body, {
      token: true,
    })

    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const updateProductSet = async (body) => {
  try {
    delete body.products
    await instance.put(`/api/promotion/p-set`, body, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const removeProductSet = async (ids) => {
  const query = ids.map((id) => `productSetIds=${id}`).join('&')
  try {
    await instance.delete(`/api/promotion/p-set?${query}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const setOrderNumberForProductSet = async (body) => {
  try {
    await instance.post('/api/promotion/p-set/order-number', body, {
      token: true,
    })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const getProductSets = async (queryObj) => {
  let query = ''
  if (queryObj) {
    query = Object.entries(queryObj)
      .filter((o) => o[1])
      .reduce((pre, cur) => pre + `${cur[0]}=${cur[1]}&`, '')
  }

  try {
    const { data } = await instance.get(`/api/promotion/p-set?${query}`, {
      token: true,
    })
    return data
  } catch (error) {
    throw error
  }
}
