import { Divider } from '@mui/material'

import Box from '@mui/material/Box'

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getExhibitionPosts, getPosts } from '../../api/post'
import Navigation from '../../components/common/navigation'
import SelectBrand from '../../components/common/SelectBrand'
import SelectCategory from '../../components/common/SelectCategory'
import ItemSearchBar from '../../components/promotion/ItemSearchBar'
import ItemSearchTable from '../../components/promotion/ItemSearchTable'
import ImageSlider from '../../components/promotion/Slider'
import RadioSelect from '../../components/RadioSelect'
import { Button } from '../../element/Button'
import { MAX_POST, MAX_TOP_POST } from './promoRegister'

const RegisterItemPage = ({ type, setOnRegister, campaign, setCampaign }) => {
  const [items, setItems] = useState([])
  const [checked, setChecked] = useState(
    type === 'top' ? campaign.topPosts : campaign.posts
  )
  const [option, setOption] = useState({
    page: 1,
    count: 0,
    brand: '',
    category: { id: null, name: '', korName: '', children: [], parent: null },
    isDiscount: '',
  })
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getExhibitionPosts({
        page: option.page,
        keyword,
        brand: option.brand,
        category: option.category,
        isDiscount: option.isDiscount,
        productIds: campaign.products.map((p) => p.id),
      })
      if (success) {
        setItems(data.data)
        setOption((prev) => ({
          ...prev,
          count: data.count,
        }))
      }
    })()
  }, [option.page])

  const updateItems = () => {
    if (type === 'top') {
      const result = Array.from(
        checked.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      )
      if (result.length > MAX_TOP_POST) {
        return alert(`최대 ${MAX_TOP_POST}개까지 등록가능합니다.`)
      }
      setCampaign((prev) => ({ ...prev, topPosts: result }))
    } else if (type === 'post') {
      const result = Array.from(
        checked.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      )
      if (result.length > MAX_POST) {
        return alert(`최대 ${MAX_POST}개까지 등록가능합니다.`)
      }
      setCampaign((prev) => ({ ...prev, posts: result }))
    }

    setChecked([])
    setOnRegister(null)
  }

  const search = async (keyword) => {
    if (option.page !== 1) {
      setOption((prev) => ({ ...prev, page: 1 }))
      return
    }

    const { success, data } = await getExhibitionPosts({
      page: option.page,
      keyword,
      brand: option.brand,
      category: option.category,
      isDiscount: option.isDiscount,
      productIds: campaign.products.map((p) => p.id),
    })
    if (success) {
      setItems(data.data)
      setOption((prev) => ({ ...prev, page: 1, count: data.count }))
    }
  }

  return (
    <Box sx={boxStyle}>
      <Box display="flex" alignItems="center" gap={3}>
        <SubTitle>상단 스냅 추가</SubTitle>
        <p>또는 스냅사진 추가</p>
      </Box>
      <Divider sx={{ height: 16 }} />
      <Box
        sx={{ marginTop: 3, marginBottom: 1 }}
        display="flex"
        alignItems="center"
        gap={3}
      >
        <SubTitle>추가된 스냅 {checked.length}개</SubTitle>
      </Box>
      <ImageSlider images={defaultImage(checked.map((c) => c.imgs[0]))} />
      <Box height={10} />

      <Box
        marginY="20px"
        gap={1.5}
        sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
      >
        <SelectCategory category={option.category} setOption={setOption} />
      </Box>

      <Box
        marginY="20px"
        gap={3}
        sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
      >
        <SelectBrand value={option.brand} setValue={setOption} />
      </Box>

      <Box marginY="20px">
        <RadioSelect
          width="auto"
          label="할인상태"
          list={[
            { name: '전체', value: '' },
            { name: '할인', value: true },
            { name: '미할인', value: false },
          ]}
          setState={(value) => {
            setOption({
              ...option,
              isDiscount: value,
            })
          }}
          state={option.isDiscount}
        />
      </Box>

      <ItemSearchBar
        placeholder="상품번호, 스타일 태그 또는 브랜드 입력"
        onClick={search}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      <Box height={20} />
      <Box width="1100px">
        <ItemSearchTable
          items={items}
          checked={checked}
          setChecked={setChecked}
        />
        <Navigation
          page={option.page}
          setPage={(page) => {
            setOption((prev) => ({ ...prev, page: page }))
          }}
          count={option.count}
        />
      </Box>
      <Box display="flex" gap={2}>
        <Button
          borderRadius="10px"
          bg="#FFF0F0"
          color="#F57979"
          onClick={() => {
            setOnRegister(null)
          }}
        >
          취소
        </Button>
        <Button borderRadius="10px" onClick={updateItems}>
          저장하기
        </Button>
      </Box>
    </Box>
  )
}
export default RegisterItemPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
  color: '#707070',
}

const SubTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: black;
`

const defaultImage = (images) => {
  if (images.length < 12) {
    images = images.concat(new Array(12 - images.length).fill(''))
  }
  return images
}
