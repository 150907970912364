import { Divider, Stack, TextField, Typography, styled } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import {
  createReserveAlarm,
  getReserveAlarms,
  updateReserveAlarm,
} from '../../api/alarm'
import { getImgUrls } from '../../api/promotion'
import AlarmListTable from '../../components/alarm/alarm-list-table'
import Navigation from '../../components/common/navigation'
import { PreviewImage } from '../../components/promotion/Register'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import addPhotoIcon from '../../static/add_snap.svg'

const AlarmListPage = () => {
  const [option, setOption] = useState({
    page: 1,
    count: 0,
    detailId: '',
    title: '',
    content: '',
    sendAt: dayjs().startOf('day'),
    thumbImg: null,
  })
  const [alarms, setAlarms] = useState([])
  const [editId, setEditId] = useState('')

  useEffect(() => {
    const find = alarms.filter((a) => a.id === editId)?.[0]

    setOption((prev) => ({
      ...prev,
      title: find?.title ?? '',
      content: find?.content ?? '',
      sendAt: find?.sendAt
        ? dayjs(find?.sendAt).add(-13, 'h')
        : dayjs().startOf('day'),
      detailId: find?.detailId ?? '',
      thumbImg: find?.thumbImg ?? null,
    }))
  }, [editId])

  useEffect(() => {
    ;(async () => {
      const data = await getReserveAlarms(option)
      setAlarms(data.data)
      if (option.count !== data.count) {
        setOption({ ...option, count: data.count })
      }
    })()
  }, [option.page])

  const clickSearchBtn = async () => {
    const { success, data } = await createReserveAlarm({
      ...option,
      sendAt: option.sendAt.add(13, 'h').toISOString(),
    })
    if (!success) {
      alert('에러 발생')
    } else {
      setAlarms((prev) => [data, ...prev])
      setOption((prev) => ({
        ...prev,
        title: '',
        content: '',
        detailId: '',
        sendAt: dayjs().startOf('day'),
        thumbImg: null,
        count: prev.count + 1,
      }))
      alert('등록완료')
    }
  }

  const ref = useRef()

  const handleImgClick = () => {
    ref.current.click()
  }

  const handleImgChange = async (event) => {
    if (!event.target.files) {
      return alert('잘못된 이미지 형식입니다.')
    }

    const file = event.target.files[0]

    if (!/\.(jpg|jpeg|png|gif)$/.test(file.name.toLowerCase())) {
      return alert('잘못된 형식의 이미지입니다.')
    }

    const { success, data } = await getImgUrls([file])
    if (!success) {
      return alert('이미지 업로드에 실패하였습니다.')
    }

    setOption((prev) => ({ ...prev, thumbImg: data[0] }))
  }

  const clickUpdateBtn = async () => {
    const find = alarms.filter((a) => a.id === editId)?.[0]
    if (!find) return

    const body = {
      detailId: option.detailId,
      title: option.title,
      content: option.content,
      sendAt: option.sendAt.add(13, 'h').toISOString(),
      thumbImg: option.thumbImg,
    }
    const { success } = await updateReserveAlarm(editId, body)
    if (!success) return alert('에러 발생')

    setAlarms((prev) => {
      const findItem = prev.findIndex((f) => f.id === editId)
      prev[findItem] = body
      return prev
    })
    setOption((prev) => ({
      ...prev,
      title: '',
      content: '',
      detailId: '',
      sendAt: dayjs().startOf('day'),
      thumbImg: null,
      count: prev.count + 1,
    }))
    setEditId('')
    alert('등록완료')
  }

  return (
    <DefaultLayout isLoggedIn>
      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            알림 등록
          </p>

          <Box width={30} />
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              제목 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={option.title}
            onChange={(e) => {
              if (e.target.value.length > 30) {
                return
              }
              setOption({ ...option, title: e.target.value })
            }}
          />

          <Box display="flex" flexDirection="column-reverse" marginLeft={1}>
            <p>{option.title.length}/30</p>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              본문 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={option.content}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                return
              }
              setOption({ ...option, content: e.target.value })
            }}
          />

          <Box display="flex" flexDirection="column-reverse" marginLeft={1}>
            <p>{option.content.length}/100</p>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              프로모션id :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={option.detailId}
            onChange={(e) => {
              const value = e.target.value.trim()
              if (value.length > 36) {
                return
              }
              setOption({ ...option, detailId: value })
            }}
          />
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <Box display="flex" flexDirection="column">
              <p
                style={{
                  color: '#707070',
                }}
              >
                발송일시 :
              </p>
              <p style={{ fontSize: '10px' }}>*미국 동부시 기준</p>
            </Box>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <DateTimePicker
              value={option.sendAt}
              onChange={(e) => {
                if (!isValidDate(dayjs().startOf('day'), e)) {
                  return
                }

                setOption((prev) => ({ ...prev, sendAt: e }))
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              이미지 첨부 :
            </p>
          </LeftBox>

          <Box display="flex" gap={3}>
            <Box display="flex" alignItems="end">
              <HoverImg
                onClick={() => {
                  handleImgClick(false)
                }}
                alt=""
                src={addPhotoIcon}
              />
              <Typography fontSize={10} color="#707070">
                정방형 60*60
              </Typography>
            </Box>

            {option.thumbImg && <PreviewImage alt="" src={option.thumbImg} />}

            <input
              type="file"
              accept=".jpg, .jpeg, .png, .gif"
              style={{ display: 'none' }}
              onChange={(e) => {
                handleImgChange(e)
              }}
              ref={ref}
            />
          </Box>
        </OptionContainer>

        <OptionContainer>
          {editId ? (
            <Stack gap={1} direction="row">
              <Button
                size="large"
                bold
                borderRadius={2}
                height="40px"
                onClick={clickUpdateBtn}
              >
                <p>수정하기</p>
              </Button>
              <Button
                size="large"
                bold
                borderRadius={2}
                height="40px"
                onClick={() => setEditId('')}
              >
                <p>취소하기</p>
              </Button>
            </Stack>
          ) : (
            <Button
              size="large"
              bold
              borderRadius={2}
              height="40px"
              sx={{}}
              onClick={clickSearchBtn}
            >
              <p>등록하기</p>
            </Button>
          )}
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography sx={{ fontWeight: 700 }}>알림 정보</Typography>

        <Divider sx={{ height: 30 }} />
        <Divider sx={{ height: 30 }} />

        <AlarmListTable
          alarms={alarms}
          setAlarms={setAlarms}
          setOption={setOption}
          setEditId={setEditId}
        />

        <Navigation
          page={option.page}
          setPage={(value) => {
            setOption({ ...option, page: value })
          }}
          count={option.count}
        />
      </Box>
    </DefaultLayout>
  )
}
export default AlarmListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const LeftBox = ({ children, ai }) => (
  <Box
    width={150}
    display="flex"
    justifyContent="center"
    alignItems={ai ? ai : 'center'}
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

export const isValidDate = (startAt, endAt) => {
  if (startAt && endAt) {
    const start = new Date(startAt)
    const end = new Date(endAt)

    if (end - start > 1000 * 60 * 60 * 24 * 365) {
      alert('조회기간은 최대 1년 이내로 선택해주세요.')
      return false
    }

    return true
  }
}

const HoverImg = styled('img')({
  cursor: 'pointer',
  width: '100px',
  height: '100px',
  margin: '0 10px',
})
