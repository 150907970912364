import { Button as ButtonEl } from '@mui/material'

export const Button = ({
  children,
  onClick,
  color,
  width,
  height,
  bg,
  bold,
  fs,
  size,
  borderRadius,
  border,
  sx,
  disabled,
}) => {
  bg = bg || 'black'
  return (
    <ButtonEl
      size={size}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        width,
        height,
        color: color || 'white',
        backgroundColor: bg,
        fontWeight: bold ? 'bold' : '',
        fontSize: fs || '16px',
        ':hover': { backgroundColor: bg, filter: 'brightness(0.9)' },
        borderRadius,
        border,
        boxShadow: 'none',
        ...sx,
      }}
    >
      {children}
    </ButtonEl>
  )
}
