import { Divider } from '@mui/material'
import { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import styled from 'styled-components'
import BasicDatePicker from '../../components/common/DataPicker'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import addPhotoIcon from '../../static/add_snap.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import {
  createPromotion,
  getImgUrls,
  updatePromotion,
} from '../../api/promotion'
import { CheckSnackbar } from '../../components/Snackbar'
import DragDropItems from '../../components/product/DragDropItems'
import {
  CampaignType,
  MAX_POST,
  MAX_PRODUCT,
  MAX_TOP_POST,
} from '../../pages/promotion/promoRegister'
import RegisterItemPage from '../../pages/promotion/registerPost'
import RegisterProduct from '../../pages/promotion/registerProduct'
import RadioSelect from '../RadioSelect'
import { UpdateImg } from './GlobalRegister'

const DISCOUNT_STATUS = [
  { name: '일반', value: false },
  { name: '할인', value: true },
]

const PromotionRegister = ({ campaign, setCampaign }) => {
  const navigation = useNavigate()
  const searchParams = new URLSearchParams(useLocation().search)
  const id = searchParams.get('id')

  // top, post, product
  const [onRegister, setOnRegister] = useState(null)

  const registerPromotion = async () => {
    const reqPromo = id ? updatePromotion : createPromotion

    const { success } = await reqPromo({
      promotionId: id,
      ...campaign,
      postIds: campaign.posts.map((p) => p.id),
      topPostIds: campaign.topPosts.map((p) => p.id),
      productIds: campaign.products.map((p) => p.id),
    })
    if (!success) {
      return alert('등록에 실패하였습니다')
    }

    setOpen(true)
    setTimeout(() => {
      navigation('/promo/admin')
    }, 500)
  }

  const promoType = [
    {
      name: '전사프로모션',
      value: CampaignType.Global,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Global })
      },
    },
    {
      name: '브랜드',
      value: CampaignType.Brand,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Brand })
      },
    },
    {
      name: '모음전',
      value: CampaignType.Showcase,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Showcase })
      },
    },
  ]

  const ref = useRef()
  const [imgKey, setKey] = useState('')
  const handleImgClick = (key) => {
    ref.current.click()
    setKey(key)
  }

  const handleImgChange = async (event) => {
    if (!event.target.files) {
      return alert('잘못된 이미지 형식입니다.')
    }

    const file = event.target.files[0]

    if (!/\.(jpg|jpeg|png|gif)$/.test(file.name.toLowerCase())) {
      return alert('잘못된 형식의 이미지입니다.')
    }

    const { success, data } = await getImgUrls([file])
    if (!success) {
      return alert('이미지 업로드에 실패하였습니다.')
    }

    setCampaign((prev) => ({ ...prev, [imgKey]: data[0] }))
  }

  const [open, setOpen] = useState(false)

  const getDiscountRateUS = campaign.products.reduce(
    (pre, cur) => (pre < cur.discountRateUS ? cur.discountRateUS : pre),
    0
  )

  const [loading, setLoading] = useState(false)

  return (
    <>
      <CheckSnackbar open={open} setOpen={setOpen} icon={true} />
      {onRegister && onRegister !== 'product' && (
        <RegisterItemPage
          type={onRegister}
          setOnRegister={setOnRegister}
          campaign={campaign}
          setCampaign={setCampaign}
        />
      )}
      {onRegister === 'product' && (
        <RegisterProduct
          type={onRegister}
          setOnRegister={setOnRegister}
          campaign={campaign}
          setCampaign={setCampaign}
        />
      )}

      <Box sx={boxStyle} display={onRegister ? 'none' : ''}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            기본정보 등록
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p>기획전 타입 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {promoType.map(({ name, value, onClick }, idx) =>
                value === campaign.type ? (
                  <Button
                    key={idx}
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                ) : (
                  <Button
                    key={idx}
                    color="#707070"
                    bg="white"
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p>기획전 제목 :</p>
          </LeftBox>

          <input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            style={{ display: 'none' }}
            onChange={handleImgChange}
            ref={ref}
          />

          <InputTextField
            width="500px"
            label=""
            multiline
            value={campaign.title}
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            onChange={(e) => {
              setCampaign((prev) => ({ ...prev, title: e.target.value }))
            }}
          />
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p>기획전 부제 :</p>
          </LeftBox>

          <InputTextField
            width="500px"
            multiline
            value={campaign.subTitle}
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            onChange={(e) => {
              setCampaign((prev) => ({
                ...prev,
                subTitle: e.target.value,
              }))
            }}
          />
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p>기획전 URL :</p>
          </LeftBox>

          <p>기획전 URL이 자동생성 됩니다.</p>
        </OptionContainer>

        <RadioSelect
          label="구분"
          labelBold={true}
          list={DISCOUNT_STATUS}
          state={campaign.isDiscount}
          setState={(value) => {
            setCampaign((prev) => ({
              ...prev,
              isDiscount: value,
            }))
          }}
        />

        <OptionContainer>
          <LeftBox>
            <p>최대할인율 :</p>
          </LeftBox>

          <Box>
            {campaign.products.length === 0
              ? '등록된 상품 할인율을 기준으로 자동계산됩니다.'
              : getDiscountRateUS + '%'}
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p>기획전 기간 :</p>
          </LeftBox>

          <Box>
            <BasicDatePicker date={campaign} setDate={setCampaign} />
          </Box>
        </OptionContainer>

        <RegisterImage
          type="thumbImg"
          title="썸네일 이미지"
          campaign={campaign}
          setCampaign={setCampaign}
          handleImgClick={handleImgClick}
          colorValue={campaign.thumbBgColor}
        />

        <OptionContainer>
          <LeftBox ai="start">
            <Box>
              <p>상단 이미지 :</p>
              <LeftText>단일 이미지</LeftText>
              <LeftText>브랜드 : 700x700</LeftText>
              <LeftText>모음전 : 800x230</LeftText>
            </Box>
          </LeftBox>

          <Box>
            <HoverImg
              onClick={() => {
                handleImgClick('topImg')
              }}
              alt=""
              src={addPhotoIcon}
            />

            {campaign.topImg && <PreviewImage alt="" src={campaign.topImg} />}
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox ai="start">
            <p>상품 등록 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: 3,
                color: '#707070',
              }}
            >
              <Button
                sx={{ marginLeft: '10px' }}
                bg="white"
                color="#707070"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setOnRegister('product')
                }}
              >
                + 상품추가
              </Button>

              <p>최대 {MAX_PRODUCT}개</p>

              <Button
                sx={{ marginLeft: '10px' }}
                bg="#FFF0F0"
                color="#FF0000"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setCampaign((prev) => ({ ...prev, products: [] }))
                }}
              >
                전체삭제
              </Button>
            </Box>

            <DragDropItems
              width={130}
              items={campaign.products}
              setItems={(items) => {
                setCampaign({ ...campaign, products: items })
              }}
            />
          </Box>
        </OptionContainer>

        {campaign.type === CampaignType.Brand && (
          <OptionContainer>
            <LeftBox ai="start">
              <p>상단 스냅추가 :</p>
            </LeftBox>

            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: 3,
                  color: '#707070',
                }}
              >
                <Button
                  sx={{ marginLeft: '10px' }}
                  bg="white"
                  color="#707070"
                  border="1px solid #e4e4e4"
                  width={100}
                  height={40}
                  fs={14}
                  onClick={() => {
                    setOnRegister('top')
                  }}
                >
                  + 스냅추가
                </Button>

                <p>{MAX_TOP_POST}개 스냅 등록</p>
              </Box>

              <DragDropItems
                width={130}
                height={130}
                items={campaign.topPosts}
                setItems={(items) => {
                  setCampaign({ ...campaign, topPosts: items })
                }}
              />
            </Box>
          </OptionContainer>
        )}

        <OptionContainer>
          <LeftBox ai>
            <p>스냅사진 등록 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: 3,
                color: '#707070',
              }}
            >
              <Button
                sx={{ marginLeft: '10px' }}
                bg="white"
                color="#707070"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setOnRegister('post')
                }}
              >
                + 스냅추가
              </Button>

              <p>최대 {MAX_POST}개</p>

              <Button
                sx={{ marginLeft: '10px' }}
                bg="#FFF0F0"
                color="#FF0000"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setCampaign((prev) => ({ ...prev, posts: [] }))
                }}
              >
                전체삭제
              </Button>
            </Box>

            <DragDropItems
              width={130}
              height={130}
              items={campaign.posts}
              setItems={(items) => {
                setCampaign({ ...campaign, posts: items })
              }}
            />
          </Box>
        </OptionContainer>

        <Box marginTop={10} display="flex" gap={3}>
          <Button
            color="#f57979"
            bg="#fff0f0"
            width="80px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={() => {
              navigation('/promo/admin')
            }}
          >
            취소
          </Button>

          <Button
            width="110px"
            height="50px"
            borderRadius="10px"
            bold
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              try {
                await registerPromotion()
              } catch (error) {
                alert('에러발생')
              } finally {
                setLoading(false)
              }
            }}
          >
            {id ? '수정하기' : '등록하기'}
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default PromotionRegister

export const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children, ai }) => (
  <Box
    width={100}
    display="flex"
    justifyContent={ai ? ai : 'center'}
    flexDirection="column"
    alignItems="end"
    marginRight={3}
    fontWeight={700}
    color="#707070"
  >
    {children}
  </Box>
)

export const LeftText = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
`

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

export const HoverImg = styled('img')({
  cursor: 'pointer',
  width: '140px',
  height: '140px',
  margin: '0 10px',
})

export const PreviewImage = styled('img')`
  width: ${(props) => (props.small ? '100px' : '140px')};
  height: ${(props) => (props.small ? '100px' : '140px')};
  margin: 0 10px;
  object-fit: contain;
`

const RegisterImage = ({ type, title, subTitle, handleImgClick, campaign }) => {
  return (
    <>
      <Box marginY="20px">
        <OptionContainer>
          <LeftBox ai="start">
            <Box>
              <p>{title} :</p>
              {subTitle && <p style={{ fontSize: '12px' }}>{subTitle}</p>}
            </Box>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={5}>
              <UpdateImg
                title="PC"
                url={campaign[type]}
                onClick={() => {
                  handleImgClick(type)
                }}
                recommendPixel={'390x620'}
              />

              <UpdateImg
                title="MOBILE"
                url={
                  campaign['m' + type.charAt(0).toUpperCase() + type.slice(1)]
                }
                onClick={() => {
                  handleImgClick(
                    'm' + type.charAt(0).toUpperCase() + type.slice(1)
                  )
                }}
              />
            </Box>
          </Box>
        </OptionContainer>
      </Box>
    </>
  )
}
