import 'dayjs/locale/ko'

import { Route, Routes } from 'react-router-dom'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AlarmListPage from './pages/alarm/alarmList'
import HomePage from './pages/home'
import InstaPage from './pages/insta'
import NoticePage from './pages/notice'
import AddNoticePage from './pages/notice/addNotice'
import EditNoticePage from './pages/notice/editNotice'
import PartnersNoticePage from './pages/notice/partners'
import PostListPage from './pages/post'
import EditPostPage from './pages/post/EditPost'
import EditProduct from './pages/product/EditProduct'
import ProductListPage from './pages/product/productList'
import PromotionAdminPage from './pages/promotion/promoAdmin'
import PromotionRegisterPage from './pages/promotion/promoRegister'
import ReturnListPage from './pages/return/returnList'
import ReturnRegisterListPage from './pages/return/returnRegisterList'
import CouponIssuedPage from './pages/reward/couponIssued'
import CouponListPage from './pages/reward/couponList'
import CreditListPage from './pages/reward/creditList'
import TransactionDetailPage from './pages/transaction/detail'
import TransactionListPage from './pages/transaction/list'
import SellListPage from './pages/transaction/sellList'
import SellerListPage from './pages/user/sellerList'
import UserListPage from './pages/user/userList'
import { KeepProvider } from './provider/keep'
import { ProductSearchProvider } from './provider/productSearch'
import ProductAdminPage from './pages/promotion/productAdmin'

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <ProductSearchProvider>
          <KeepProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/user/seller" element={<SellerListPage />} />
              <Route path="/user" element={<UserListPage />} />
              <Route path="/snap" element={<PostListPage />} />
              <Route path="/snap/add/:id" element={<EditPostPage />} />
              <Route path="/product" element={<ProductListPage />} />
              <Route path="/product/add" element={<EditProduct />} />
              <Route path="/notice" element={<NoticePage />} />
              <Route path="/notice/add" element={<AddNoticePage />} />
              <Route path="/notice/:id" element={<EditNoticePage />} />
              <Route path="/notice-seller" element={<PartnersNoticePage />} />

              <Route
                path="/transaction/list"
                element={<TransactionListPage />}
              />
              <Route path="/transaction/sell" element={<SellListPage />} />
              <Route
                path="/transaction/detail"
                element={<TransactionDetailPage />}
              />
              <Route
                path="/return/register"
                element={<ReturnRegisterListPage />}
              />
              <Route path="/return/list" element={<ReturnListPage />} />
              <Route path="/reward/coupon" element={<CouponListPage />} />
              <Route path="/reward/credit" element={<CreditListPage />} />
              <Route
                path="/reward/coupon-issued"
                element={<CouponIssuedPage />}
              />

              <Route
                path="/promo/register"
                element={<PromotionRegisterPage />}
              />
              <Route path="/promo/admin" element={<PromotionAdminPage />} />
              <Route path="/promo/product" element={<ProductAdminPage />} />
              <Route path="/alarm" element={<AlarmListPage />} />
              <Route path="/insta" element={<InstaPage />} />
            </Routes>
          </KeepProvider>
        </ProductSearchProvider>
      </LocalizationProvider>
    </DndProvider>
  )
}

export default App
